import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { getManagedReportState } from '../../utils';
import UploadDocument from '../upload/UploadDocument';
import { resetReport } from '../../actions/timeReportActions';
import logger from '../../logger';
import { DocumentModeDictionary } from '../../utils/handleDocumentMode';
import TimePickerComponent from '../calendars/TimePickerComponent';

const initValues = {
  id: undefined,
  activity_id: undefined,
  in_time: null,
  location_id: undefined,
  out_time: null,
  project_id: undefined,
  user_id: undefined,
  date: undefined,
  dateRange: undefined,
  comments: '',
};

const TimeReportGridForm = ({
  documentMode,
  setDocumentMode,
  onFinish,
  onUpdateFiles,
  projects = [],
  locations = [],
  getActivities,
  disabledDate,
  getUserTimeReportFromClock,
  userPreferences,
  loading,
}) => {
  const managedReport = useSelector((state) => getManagedReportState(state));
  const dispatch = useDispatch();
  const [projectOptions, setProjectOptions] = useState([]);
  const [activitiesOptions, setActivitiesOptions] = useState([]);
  // const [disableSave, setDisableSave] = useState(true);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [formValues, setFormValues] = useState(initValues);
  const [fileList, setFileList] = useState([]);
  const [isGeneral, setIsGeneral] = useState(false);

  const [form] = Form.useForm();

  const normFile = (e) => {
    // logger.info('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onFieldValueChanged = (_, allFields) => {
    // const hasErrors = form.getFieldsError()?.some(({ errors }) => errors.length);

    if (_[0].name[0] === 'project_id' && _[0].value !== formValues.project_id) {
      form.setFieldValue('activity_id', undefined);
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        activity_id: undefined,
      }));
      setDocumentMode(DocumentModeDictionary.NO_NEED); //reset docment mode when clearing activity
      setActivities([]);
    }

    if (_[0].name[0] === 'activity_id' && _[0].value !== formValues.activity_id) {
      form.setFields([
        {
          name: 'upload',
          errors: [],
        },
      ]);
      setDocumentMode(() => {
        const document_mode = activities.find((activity) => activity.id === _[0].value)?.document_mode;
        return DocumentModeDictionary[document_mode ?? 'NO_NEED'];
      });
    }

    // const isFilled = allValuesFilled();
    // setDisableSave(hasErrors /*|| !isFilled*/);
    // setDisableSave(hasErrors || !form.isFieldsTouched(true));
    // setDisabledReset(!isFilled && isLocationAvailable);
  };

  const onFormValuesChange = (prop) => {
    setFormValues({ ...formValues, ...prop });
  };

  const handleDateChange = (date, dateString) => {
    if (!dateString.length) return;
    if (managedReport.formState === 'DUPLICATE') {
      return;
    }
    getUserTimeReportFromClock(form, dateString).then((foundHours) => {
      if (foundHours) {
        // Set location to office if reports are found
        const officeLocation = locationsOptions.find((loc) => loc.label === 'משרד');
        form.setFieldsValue({ location_id: officeLocation ? officeLocation.value : undefined });
      } else {
        // Reset location if no reports found
        form.resetFields(['location_id']);
      }
      if (userPreferences) {
        //insert favorite project & activity if exists
        const { favorite_project, favorite_activity } = userPreferences;
        const { project_id, activity_id } = formValues;

        if (favorite_project && projects.some((p) => p.id === favorite_project.id))
          if (!project_id && !activity_id) {
            if (favorite_project) {
              form.setFieldsValue({ project_id: favorite_project.id });
              setFormValues({ ...formValues, project_id: favorite_project.id });
              if (favorite_activity) {
                form.setFieldsValue({ activity_id: favorite_activity.id });
                setFormValues((prevFormValues) => ({
                  ...prevFormValues,
                  activity_id: favorite_activity.id,
                }));
              }
            }
          } else if (project_id && !activity_id) {
            if (project_id === favorite_project?.id && favorite_activity) {
              form.setFieldsValue({ activity_id: favorite_activity.id });
              setFormValues((prevFormValues) => ({
                ...prevFormValues,
                activity_id: favorite_activity.id,
              }));
            }
          }
      }
    });
  };

  const handleFinish = async (values) => {
    const parsedvalues = { ...initValues }; // copy the object
    // const date = dayjs(values.date);

    parsedvalues.id = managedReport?.id;
    parsedvalues.activity_id = values.activity_id;
    if (isGeneral) {
      parsedvalues.in_time = values.startDate.hour(0).minute(0).format('DD/MM/YYYY HH:mm');
      parsedvalues.out_time = values.endDate.hour(0).minute(0).format('DD/MM/YYYY HH:mm');
    } else {
      const in_hour = values.in_time ? dayjs(values.in_time).hour() : 0;
      const in_minutes = values.in_time ? dayjs(values.in_time).minute() : 0;
      const out_hour = values.out_time ? dayjs(values.out_time).hour() : 0;
      const out_minutes = values.out_time ? dayjs(values.out_time).minute() : 0;

      parsedvalues.in_time = values.date.hour(in_hour).minute(in_minutes).format('DD/MM/YYYY HH:mm');
      parsedvalues.out_time = values.date.hour(out_hour).minute(out_minutes).format('DD/MM/YYYY HH:mm');
    }
    parsedvalues.project_id = values.project_id;
    parsedvalues.user_id = values.user_id;
    parsedvalues.location_id = values.location_id;
    parsedvalues.comments = values.comments ?? '';
    if (documentMode !== DocumentModeDictionary.NO_NEED) {
      try {
        //if fileList[0].uid is undefined so it was loaded from the DB
        if (values.upload?.length > 0 && values.upload[0].uid === 'undefined') {
          parsedvalues.file_id = managedReport.file.id;
        } else {
          if (values.upload !== undefined && values.upload.length > 0) {
            await onUpdateFiles(fileList).then((file_id) => {
              if (file_id) {
                parsedvalues.file_id = file_id;
              }
            });
          }
        }
      } catch {
        return;
      }
    }

    onFinish(parsedvalues).then((success) => {
      if (success) {
        form.resetFields();
        setDocumentMode(DocumentModeDictionary.NO_NEED);
        setFormValues(initValues);
        dispatch(resetReport());
        logger.info('Time report save success');
      } else logger.info('Time report save failed');
    });
  };

  // const validateTimes = ({ getFieldValue }) => ({
  //   validator(_, value) {
  //     const inTime = getFieldValue('in_time');
  //     const inTimeFormatted = inTime.format('HH:mm');
  //     const valueFormatted = value.format('HH:mm');

  //     if (
  //       !value ||
  //       !inTime ||
  //       dayjs(valueFormatted, 'HH:mm').isAfter(dayjs(inTimeFormatted, 'HH:mm')) ||
  //       (isGeneral && dayjs(value).isSame(inTime))
  //     ) {
  //       return Promise.resolve();
  //     }
  //     return Promise.reject(new Error('זמן סיום חייב להיות אחרי זמן התחלה'));
  //   },
  // });

  const checkIfDisableEndDate = (value) => {
    // checks if endDate is greater than start date
    const disDate = disabledDate(value);
    const res = dayjs(value).isBefore(form.getFieldValue('startDate'));
    return res || disDate;
  };

  const handleDisableDate = (currentDate) => {
    const disDate = disabledDate(currentDate);
    const weekend = currentDate.day() === 6;
    return disDate || weekend;
  };

  useEffect(() => {
    setProjectOptions(
      projects.map((project) => ({
        value: project.id,
        label: project.project_name,
      }))
    );
    setLocationsOptions(
      locations.map((location) => ({
        value: location.id,
        label: location.location_name,
      }))
    );
    setActivitiesOptions(
      activities.map((activity) => ({
        value: activity.id,
        label: activity.activity_name,
      }))
    );
  }, [activities, locations, projects]);

  useEffect(() => {
    const { id, activity_id, in_time, location_id, out_time, project_id, user_id, comments, file } =
      managedReport;
    if (managedReport && managedReport.user_id) {
      setFormValues({
        ...formValues,
        id,
        activity_id,
        in_time: dayjs(in_time),
        date: dayjs(in_time),
        startDate: dayjs(in_time),
        endDate: dayjs(out_time),
        location_id,
        out_time: dayjs(out_time),
        project_id,
        user_id,
        comments,
        upload: file
          ? [
              {
                uid: `${file.file_id}`,
                name: file.file_name,
                status: 'done',
              },
            ]
          : [],
        //get file name and URL
      });
      form.setFieldsValue({
        ...formValues,
        id,
        activity_id,
        in_time: dayjs(in_time),
        date: dayjs(in_time),
        startDate: dayjs(in_time),
        endDate: dayjs(out_time),
        location_id,
        out_time: dayjs(out_time),
        project_id,
        user_id,
        comments,
        upload: file
          ? [
              {
                uid: `${file.file_id}`,
                name: file.file_name,
                status: 'done',
              },
            ]
          : [],
        //get file name and URL
      });
    } else if (managedReport && project_id && activity_id && location_id && in_time && out_time) {
      setFormValues({
        ...formValues,
        project_id: project_id,
        activity_id: activity_id,
        location_id: location_id,
        in_time: dayjs(in_time),
        out_time: dayjs(out_time),
      });
      form.setFieldsValue({
        ...formValues,
        project_id: project_id,
        activity_id: activity_id,
        location_id: location_id,
        in_time: dayjs(in_time),
        out_time: dayjs(out_time),
      });
    } else {
      form.resetFields();
      setDocumentMode(DocumentModeDictionary.NO_NEED);
      setFormValues(initValues);
      dispatch(resetReport());
    }
    // eslint-disable-next-line
  }, [managedReport]);

  useEffect(() => {
    if (managedReport && managedReport.activity_id) {
      setDocumentMode(() => {
        const document_mode = activities.find(
          (activity) => activity.id === managedReport.activity_id
        )?.document_mode;
        return DocumentModeDictionary[document_mode];
      });
    }
  }, [activities, managedReport, setDocumentMode]);

  useEffect(() => {
    if (formValues.project_id) {
      var project = projects.find((project) => project.id === formValues.project_id);
      if (project) {
        setIsGeneral(project.is_general);
      }
      getActivities(formValues.project_id).then((res) => {
        if (res) setActivities(res.data);
      });
    } else setIsGeneral(false);
  }, [form, formValues.project_id, projects, getActivities, managedReport]);

  return (
    <Form
      form={form}
      name='edit_report'
      layout={'vertical'}
      onFinish={handleFinish}
      onFieldsChange={onFieldValueChanged}
      onValuesChange={onFormValuesChange}
    >
      {!isGeneral && (
        <Col span={24}>
          <Form.Item
            name='date'
            validateTrigger='onSubmit'
            label='תאריך דיווח'
            rules={[
              {
                required: true,
                message: 'נא לבחור תאריך',
              },
            ]}
          >
            <DatePicker
              placeholder='בחר תאריך'
              disabledDate={handleDisableDate}
              format={'DD/MM/YYYY'}
              inputReadOnly={true}
              style={{
                width: '100%',
              }}
              onChange={handleDateChange}
            />
          </Form.Item>
        </Col>
      )}

      {/* project & activity */}
      <Row gutter={12}>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='project_id'
            validateTrigger='onSubmit'
            label='פרויקט'
            rules={[
              {
                required: true,
                message: 'נא לבחור פרויקט',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              options={projectOptions}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder='בחר פרויקט'
            />
          </Form.Item>
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          lg={12}
          xl={12}
        >
          <Form.Item
            name='activity_id'
            validateTrigger='onSubmit'
            label='פעילות'
            rules={[
              {
                required: true,
                message: 'נא לבחור פעילות',
              },
            ]}
          >
            <Select
              allowClear
              showSearch
              options={activitiesOptions}
              filterOption={(input, option) => (option?.label ?? '').includes(input)}
              placeholder='בחר פעילות'
            />
          </Form.Item>
        </Col>
      </Row>

      {/*date and location*/}
      <Row gutter={12}>
        {isGeneral && (
          <>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                name='startDate'
                validateTrigger='onSubmit'
                label='תאריך התחלה'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך התחלה',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={disabledDate}
                  className='w-100'
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
            >
              <Form.Item
                name='endDate'
                validateTrigger='onSubmit'
                label='תאריך סיום'
                rules={[
                  {
                    required: true,
                    message: 'נא לבחור תאריך סיום',
                  },
                ]}
              >
                <DatePicker
                  disabledDate={checkIfDisableEndDate}
                  className='w-100'
                  inputReadOnly={true}
                />
              </Form.Item>
            </Col>
          </>
        )}

        <Col span={24}>
          <Form.Item
            name='location_id'
            validateTrigger='onSubmit'
            label='מיקום'
            rules={[
              {
                required: true,
                message: 'נא לבחור מיקום',
              },
            ]}
          >
            <Select
              allowClear
              options={locationsOptions}
              optionFilterProp='children'
              placeholder='בחר מיקום'
            />
          </Form.Item>
        </Col>
      </Row>
      {/* start time and end time */}
      {!isGeneral && (
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name='in_time'
              validateTrigger='onSubmit'
              label='בחר זמן התחלה'
              rules={[
                {
                  required: !isGeneral,
                  message: 'נא לבחור זמן התחלה',
                },
              ]}
              dependencies={['out_time']}
            >
              <TimePickerComponent />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name='out_time'
              validateTrigger='onSubmit'
              label='בחר זמן סיום'
              rules={[
                {
                  required: !isGeneral,
                  message: 'נא לבחור זמן סיום',
                },
                // validateTimes, // until fixing the issue at endTime
              ]}
              dependencies={['in_time']}
            >
              <TimePickerComponent />
            </Form.Item>
          </Col>
        </Row>
      )}
      {/* Comments */}
      <Row>
        <Col span={24}>
          <Form.Item
            name='comments'
            label='הערות'
          >
            <Input.TextArea
              placeholder='הוסף הערות'
              allowClear
              maxLength={200}
              showCount
              autoSize={{
                minRows: 2,
                maxRows: 4,
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      {/* form buttons */}
      <Row
        gutter={12}
        justify={'space-between'}
      >
        <Col>
          {isGeneral && documentMode !== DocumentModeDictionary.NO_NEED && (
            <Form.Item
              name='upload'
              valuePropName='fileList'
              getValueFromEvent={normFile}
              validateTrigger='onSubmit'
              rules={[
                {
                  required: documentMode === DocumentModeDictionary.MANDATORY,
                  message: 'נא לצרף קובץ!',
                },
              ]}
            >
              <UploadDocument
                name='attachment'
                fileList={fileList}
                onChange={(info) => setFileList(info.fileList)}
              />
            </Form.Item>
          )}
        </Col>
        <Col>
          <Form.Item shouldUpdate>
            {() => (
              <Button
                type='primary'
                htmlType='submit'
                // disabled={disableSave}
                loading={loading.update}
              >
                שליחה
              </Button>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default TimeReportGridForm;
