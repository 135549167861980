import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Divider,
  List,
  Radio,
  Row,
  Select,
  Spin,
  Typography,
  Tooltip,
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { selectDate } from '../../actions/timeReportActions';
import { getDateReportsState } from '../../utils';
import { styled } from 'styled-components';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

const eventTypeOptions = ['יום עבודה רגיל', 'עבודה חלקית', 'משרד סגור – ע"ח קורן טק'];

const StyledCalendar = styled(Calendar)`
  .ant-picker-calendar-date-value {
    display: none;
    pointer-events: none;
  }
`;

const TimeCalendar = ({ dateEvents, loading }) => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
  const dateReports = useSelector((state) => getDateReportsState(state));
  const [selectedDateEvents, setSelectedDateEvents] = useState([]);
  const [monthNavTexts, setMonthNavTexts] = useState({ prev: '', next: '' });

  const selectCalendarDate = (value) => {
    dispatch(selectDate(value));
    setDate(value.format('YYYY-MM-DD'));
  };

  const disableTodayButton = () => dayjs().isSame(date, 'day');

  useEffect(() => {
    if (dateEvents?.length) {
      setSelectedDateEvents(dateEvents.find((d) => d.date === date)?.events || []);
    } else {
      setSelectedDateEvents([]);
    }
    if (date) {
      setMonthNavTexts({
        next: dayjs(date).add(1, 'month').format('MMMM'),
        prev: dayjs(date).subtract(1, 'month').format('MMMM'),
      });
    }
  }, [date, dateEvents]);

  const renderStatusType = (event_type) => {
    switch (event_type) {
      case 0:
        return 'blue'; // default
      case 1:
        return 'gold'; // missing
      case 2:
        return 'green'; // reported
      case 3:
        return 'red'; // not reported
      default:
        return ''; // not saved
    }
  };

  const markDates = (dateToCheck, today) => {
    const diffInMonths = dayjs(today).diff(dayjs(dateToCheck), 'month');
    if (diffInMonths === 0) {
      return dayjs(today).isAfter(dayjs(dateToCheck), 'day');
    }
    return false;
  };

  const markReportedDates = (date) => {
    return dateReports.some((dateReport) => dateReport.date === date);
  };

  const dateCellRender = (value) => {
    const today = dayjs();
    const formattedDate = dayjs(value).format('YYYY-MM-DD');
    const targetElement = dateEvents.find((date_event) => date_event.date === formattedDate);
    let type;

    const isCurrentMonth = dayjs(today).month() === dayjs(value).month();
    const isNextMonth = dayjs(date).month() < dayjs(value).month();
    const isPastDate = dayjs(value).isBefore(today, 'day');

    if (isCurrentMonth && markDates(value, today) && isPastDate) {
      type = 3; // mark default dates
    }

    if (targetElement?.events) {
      type = 0; // mark events
    }

    if (markReportedDates(formattedDate)) {
      type = 2;
    }

    if ((isNextMonth || (type !== 2 && dayjs(value).day() === 5 ) || dayjs(value).day() === 6) && !targetElement?.events) {
      type = undefined; // hide badges for the next month or weekends, except for events
    }

    return (
      <Tooltip
        title={targetElement?.events ? targetElement.events.map((event) => event.event_name).join(' | ') : ''}
      >
        <div className='custom-tooltip-cell'>
          {value.date()}
          <br />
          <Badge color={renderStatusType(type)} />
        </div>
      </Tooltip>
    );
  };

  const monthCellRender = (value) => {
    const num = value.format('MMMM');
    // dayjs().format()
    return num ?? '';
  };

  const cellRender = (current, info) => {
    return info.type === 'date'
      ? dateCellRender(current)
      : info.type === 'month'
      ? monthCellRender(current)
      : '';
    // TBD: add logic to render Badge according the reports, fully reported = green, partially reported = yellow, future months = none
  };

  const setDivider = (text) => <Divider className='my-1'>{loading ? <Spin /> : text}</Divider>;

  const headerRender = ({ value, type, onChange, onTypeChange }) => {
    const start = 0;
    const end = 12;
    const monthOptions = [];
    let current = value.clone();
    const localeData = value.localeData();
    const months = [];
    for (let i = 0; i < 12; i++) {
      current = current.month(i);
      months.push(localeData.monthsShort(current));
    }
    for (let i = start; i < end; i++) {
      monthOptions.push(
        <Select.Option
          key={i}
          value={i}
        >
          {months[i]}
        </Select.Option>
      );
    }
    const year = value.year();
    const month = value.month();
    const options = [];
    for (let i = dayjs().year() - 1; i <= dayjs().year() + 1; i += 1) {
      options.push(
        <Select.Option
          key={i}
          value={i}
        >
          {i}
        </Select.Option>
      );
    }

    return (
      <div className='p-2'>
        <Typography.Title level={4}>לוח שנה</Typography.Title>
        <Row justify={'space-between'}>
          <Col span={24}>
            <Row gutter={4}>
              <Col>
                <Radio.Group
                  size='small'
                  onChange={(e) => onTypeChange(e.target.value)}
                  value={type}
                >
                  <Radio.Button value='month'>ימים</Radio.Button>
                  <Radio.Button value='year'>חודשים</Radio.Button>
                </Radio.Group>
              </Col>
              <Col>
                <Select
                  size='small'
                  style={{
                    width: 70,
                  }}
                  value={year}
                  onChange={(newYear) => {
                    const now = value.clone().year(newYear);
                    onChange(now);
                  }}
                >
                  {options}
                </Select>
              </Col>
              <Col>
                <Select
                  size='small'
                  style={{
                    width: 70,
                  }}
                  value={month}
                  onChange={(newMonth) => {
                    const now = value.clone().month(newMonth);
                    onChange(now);
                  }}
                >
                  {monthOptions}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Row>
      <Card styles={{ body: { padding: 10 } }}>
        <Col span={24}>
          <StyledCalendar
            fullscreen={false}
            onChange={selectCalendarDate}
            cellRender={cellRender}
            headerRender={headerRender}
            value={dayjs(date)}
          />
          <Divider
            className='mb-1 mt-0'
            dashed
          />
          <Col span={24}>
            <Row justify={'space-between'}>
              <Button
                styles={{ icon: { margin: -5 } }}
                icon={<IoIosArrowForward />}
                shape='round'
                size='small'
                type='default'
                onClick={() => selectCalendarDate(dayjs(date).subtract(1, 'month'))}
              >
                {monthNavTexts.prev}
              </Button>
              <Button
                size='small'
                shape='round'
                type='primary'
                onClick={() => selectCalendarDate(dayjs())}
                disabled={disableTodayButton()}
              >
                היום
              </Button>
              <Button
                styles={{ icon: { margin: -5 } }}
                icon={<IoIosArrowBack />}
                shape='round'
                iconPosition='end'
                size='small'
                type='default'
                onClick={() => selectCalendarDate(dayjs(date).add(1, 'month'))}
              >
                {monthNavTexts.next}
              </Button>
            </Row>
          </Col>
        </Col>
      </Card>
      {selectedDateEvents?.length > 0 && !loading ? (
        <>
          {setDivider('אירועים')}
          <Col span={24}>
            <Card
              styles={{ body: { paddingTop: 0, paddingBottom: 0 } }}
              style={{ overflow: 'auto', maxHeight: 240 }}
            >
              <List
                dataSource={selectedDateEvents}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={`${item.event_name}`}
                      description={eventTypeOptions[item.event_type]}
                    />
                  </List.Item>
                )}
              />
            </Card>
          </Col>
        </>
      ) : (
        setDivider('אין אירועים')
      )}
    </Row>
  );
};

export default TimeCalendar;
